<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Entrées en stock
              </span>
            </h3>
            <div class="card-toolbar">
              <b-button v-if="csvLoading" variant="secondary" class="font-weight-bolder mr-3 d-flex flex-row align-items-center" disabled>
                <b-spinner small /> Télécharger en Excel
              </b-button>
              <b-button v-else @click.prevent="exportCsv" variant="secondary" class="font-weight-bolder mr-3 d-flex flex-row align-items-center">
                <i class="fas fa-file-download mr-4" /> Télécharger en Excel
              </b-button>

              <a href="#" v-if="hasUserRights([{ section: 'stockEntries', rights: ['create'] }])" @click.prevent="newItem" class="btn btn-primary font-weight-bolder d-flex flex-row align-items-center">
                <i class="fas fa-folder-plus mr-4"/> Nouvelle entrée en stock
              </a>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <!--begin::Table-->
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isTableLoading"
              :pagination-options="{ enabled: true }"
              :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
              :rows="rows"
              :columns="columns"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              styleClass="custom-table"
            >
              <div slot="loadingContent" class="text-center">Chargement...</div>
              <div slot="emptystate" class="text-center">Aucun élément à afficher</div>

              <template #column-filter="{ column, updateFilters }">
                <template
                  v-if="column.filterOptions && column.filterOptions.customFilter"
                >
                  <v-select
                    @input="(e) => updateFilters(column, e)"
                    @search="(search, loadingFn) => onSearchCustomFilter(column, search, loadingFn)"
                    :filterable="false"
                    :options="customFilters[column.field]"
                    :reduce="(e) => e.id"
                    label="id"
                    placeholder="Tous"
                    required
                  >
                    <template #no-options>
                      Rechercher...
                    </template>
                    <template #option="option">
                      <div class="d-center">
                        {{ column.filterOptions.displayResults(option) }}
                      </div>
                    </template>
                    <template #selected-option="option">
                      <div class="selected d-center">
                        {{ column.filterOptions.displayResults(option) }}
                      </div>
                    </template>
                  </v-select>
                </template>
              </template>

              <template slot-scope="props" slot="table-row">

                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                    <a href="#" v-if="hasUserRights([{ section: 'stockEntries', rights: ['update'] }])" @click.prevent="editItem(props.row.id)" class="mr-4">
                        <i class="fas fa-pencil-alt text-primary" />
                    </a>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '25', '50', '100']"
                      class="mr-2 custom-select-sm"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      entrées par page
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="serverParams.page"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <i class="fas fa-chevron-left"></i>
                      </template>
                      <template #next-text>
                        <i class="fas fa-chevron-right"></i>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->

    <b-modal v-model="modal.show" :title="modal.title" hide-footer no-close-on-backdrop>
     <div class="d-block">
        <b-form id="modal-form" @submit.prevent="onSubmitItem">
            <b-form-group label="Produit :">
              <v-select
                v-model="modal.item.product"
                @search="fetchProductOptions"
                :options="productOptions"
                :filterable="false"
                label="name"
                placeholder="Rechercher un produit par nom ou référence..."
                required
              >
                <div slot="no-options">Aucun produit trouvé</div>
              </v-select>
            </b-form-group>
            <b-form-group label="Date d'entrée en stock :">
              <b-form-input
                v-model="modal.item.entryDate"
                type="date"
                placeholder="Date d'entrée en stock"
                required
              />
            </b-form-group>
            <b-form-group label="Quantité :">
              <b-form-input
                v-model="modal.item.quantity"
                type="number"
                min="0"
                placeholder="Quantité"
                required
              />
            </b-form-group>
            <b-form-group label="Prix d'achat (HT) :">
              <b-form-input
                v-model="modal.item.purchasePrice"
                type="number"
                min="0"
                step="0.01"
                placeholder="Prix d'achat (HT)"
                required
              />
            </b-form-group>
            <b-form-group label="Prix de vente (HT) :">
              <b-form-input
                v-model="modal.item.sellingPrice"
                type="number"
                min="0"
                step="0.01"
                placeholder="Prix de vente (HT)"
                required
              />
            </b-form-group>

            <div class="text-right">
                <b-button class="mr-2" @click.prevent="hideModal" variant="light-primary">Annuler</b-button>
                <b-button type="submit" variant="primary">Sauvegarder</b-button>
            </div>
        </b-form>
     </div>
   </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import _ from "lodash";

export default {
  name: "stocks",
  data() {
    return {
      columns: [
        {
          label: "Produit",
          field: "product",
          sortable: true,
          formatFn: e => `${e.name} (${e.reference})`,
          filterOptions: {
            enabled: true,
            customFilter: true,
            fullMatch: true,
            filterApi: { endpoint: '/products', params: ['name_contains', 'reference_contains'] },
            displayResults: (e) => `${e.name} (${e.reference})`,
          },
        },
        {
          label: "Prix HT",
          field: "purchasePrice",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Prix HT", fullMatch: true },
          formatFn: (e) => { return e.toFixed(2) + "€" },
        },
        {
          label: "Stock entré",
          field: "quantity",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Stock entré", fullMatch: true },
        },
        { label: "Date", field: "entryDate", formatFn: this.dateToStr, sortable: true },
        { label: "", field: "action", width: "80px" },
      ],
      rows: [],
      recents: [],
      customFilters: [],
      totalRecords: 0,
      isTableLoading: true,
      csvLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "entryDate",
          type: "desc",
        },
        page: 1,
        perPage: 10,
      },
      modal: {
          show: false,
          title: "Nouvelle produit",
          item: {
            id: null,
            code: null,
            quantity: null,
            entryDate: null,
            purchasePrice: null,
            sellingPrice: null,
            product: null,
          },
      },
      productOptions: [],
    };
  },
  computed: {
    ...mapGetters(["hasUserRights"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Stocks" }]);
  },
  beforeUnmount() {
    this.$root.$off("bv::modal::shown"); 
  },
  methods: {
    fetchProductOptions(search, loading) {
      loading(true);
      this.search(search, loading, this);
    },
    search: _.debounce((search, loading, vm) => {
      ApiService
        .query(`/products`, { params: { name_contains: search, reference_contains: search, _or: 1 } })
        .then(({ data }) => {
          vm.productOptions = data.results;
          loading(false);
        })
        .catch(() => { loading(false); });
    }, 350),

    hideModal() {
        this.modal.show = false;
    },
    newItem() {
        this.modal.item = {  id: null, code: null, quantity: null, entryDate: null, purchasePrice: null, sellingPrice: null, product: null };
        this.modal.title = "Nouvelle entrée en stock";
        this.modal.show = true;
    },
    editItem(id) {
        ApiService
          .get("/product-stock-items", id)
          .then(({ data }) => {
              this.modal.item = { ...data };
              this.modal.item.entryDate = new Date(this.modal.item.entryDate).toISOString().slice(0, 10);
              this.modal.title = "Edition de l'entrée en stock";
              this.modal.show = true;
          });
    },
    onSubmitItem() {
      this.modal.item.product = this.modal.item.product.id;
      
      if (this.modal.item.id) {
        ApiService
            .update(`/product-stock-items`, this.modal.item.id, this.modal.item)
            .then(() => {
                this.fetchItemsFromApi();
                this.modal.show = false;
            });
      } else {
        ApiService
            .post(`/product-stock-items`, this.modal.item)
            .then(() => {
                this.fetchItemsFromApi();
                this.modal.show = false;
            });
      }
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Generate params for calls to the API
     */
    getApiParams() {
      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (this.columns.find(column => column.field == e)?.filterOptions?.fullMatch) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      return {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: 'product product.family',
      };
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;
      ApiService
        .query("/product-stock-items", { params: this.getApiParams() })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    /**
     * Export table to CSV file
     */
    exportCsv() {
      this.csvLoading = true;
      ApiService
        .query("/product-stock-items", { params: { ...this.getApiParams(), toCsv: 1 } })
        .then(({ data }) => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
          anchor.target = '_blank';
          anchor.download = 'entrees-stocks.csv';
          anchor.click();
          this.csvLoading = false;
        })
        .catch(() => {
          this.csvLoading = false;
        });
    },
    // CUSTOM FILTERS
    /**
     * Triggered when a search is performed on a custom filter in the table
     */
    onSearchCustomFilter(column, search, loading) {
      if (search.length) {
        loading(true);
        this.debounceCustomFilter(loading, column, search, this);
      }
    },
    /**
     * Perform custom filter via API (debounced)
     */
    debounceCustomFilter: _.debounce((loading, column, search, vm) => {
      const params = { _or: 1 };
      column.filterOptions.filterApi.params.forEach(e => {
        params[e] = search;
      });
      params.populate = column.filterOptions.filterApi.populate;

      ApiService.query(column.filterOptions.filterApi.endpoint, { params })
        .then((res) => {
          vm.customFilters[column.field] = res.data.results;
          loading(false);
        })
        .catch(() => {
          vm.customFilters[column.field] = [];
          loading(false);
        });
    }, 350),
  },
};
</script>